import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import {
  Box,
  Stack,
  Paper,
  Radio,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
// components
import Iconify from "../../components/iconify";
//
import PaymentNewCardDialog from "./PaymentNewCardDialog";
import axios from "axios";
import BAFLForm from "./BAFLForm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PaymentNewCardComponent from "./PaymentNewCardDialog";
// ----------------------------------------------------------------------

const PAYMENT_OPTIONS = [
  {
    value: "cash",
    title: "Cash",
    icons: ["/assets/icons/payments/ic_paypal.svg"],
  },
  {
    value: "card",
    title: "Credit / Debit Card",
    icons: [
      "/assets/icons/payments/ic_mastercard.svg",
      "/assets/icons/payments/ic_visa.svg",
    ],
  },
];
const CARD_OPTIONS = [
  {
    value: "visa1",
    label: "**** **** **** 1212 - Jimmy Holland",
  },
  {
    value: "visa2",
    label: "**** **** **** 2424 - Shawn Stokes",
  },
  {
    value: "mastercard",
    label: "**** **** **** 4545 - Cole Armstrong",
  },
];

// ----------------------------------------------------------------------

export default function PaymentMethods({
  method,
  setMethod,
  placeOrder,
  cashAvailable,
  serviceFee,
  taxAmount,
  total,
}) {
  const [open, setOpen] = useState(false);
  let [data, setData] = useState("");
  const [error, setError] = useState("");
  const { user } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const { cardItems } = useSelector((state) => state.cardSlice);

  const [openNewCard, setOpenNewCard] = useState(false);

  const handleOpenNewCard = () => {
    setOpenNewCard(true);
  };

  const handleOpen = () => {
    let orderType = localStorage.getItem("mode");
    if (
      (!user?.isPhoneVerified && user) ||
      (orderType == "QrPickUp" && !user?.isPhoneVerified) ||
      (orderType == "pickUp" && !user?.isPhoneVerified)
    ) {
      if (user?.phone) {
        let queryParams = new URLSearchParams();
        queryParams.append("verify", "Phone");
        navigate(`/auth/verify?${queryParams.toString()}`);
      } else {
        const message =
          "Please add and verify your phone number before placing order to avoid any mis-convenience.";
        navigate(`/phoneNumber`, { state: { message } });
      }
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChangeMethod = (event) => {
  //   setMethod(event.target.value);
  //   setOpenNewCard(false);
  // };

  const handleChangeMethod = async (event) => {
    setMethod(event.target.value);
    setError("")
    if (event.target.value === "card") {
      const checkVenueStatus =await axios.get(`${process.env.REACT_APP_BACKEND_URL}/venues/${cardItems?.data?.venueId}`)
      if(checkVenueStatus.data.status == "inActive"){
        setError("Venue is inActive, Please try again later!")
      }else{
        setOpenNewCard(true);
      }
    } else {
      setOpenNewCard(false);
    }
  };

  return (
    <>
       {error && (
          <Typography
            variant="body2"
            style={{ color: "red", textAlign: "center" }}
            >
            {error}
          </Typography>      
        )}
      <RadioGroup
        value={method}
        onChange={handleChangeMethod}
        sx={{ marginTop: 0 }}
      >
        <Stack spacing={3}>
          {PAYMENT_OPTIONS.filter((option) => {
            if (option.value === "cash" && !cashAvailable) {
              return false;
            }
            return true;
          }).map((option) => (
            <PaymentOption
              key={option.title}
              option={option}
              isSelected={method === option.value}
              hasChild={option.value === "card"}
              isCreditMethod={option.value === "card" && method === "card"}
              // onOpen={handleOpenNewCard}
            />
          ))}
        </Stack>
      </RadioGroup>

      {method === "card" && openNewCard && (
        <PaymentNewCardComponent
          method={method}
          total={total}
          serviceFee={serviceFee}
          taxAmount={taxAmount}
        />
      )}
    </>
  );
}

// ----------------------------------------------------------------------

PaymentOption.propTypes = {
  onOpen: PropTypes.func,
  hasChild: PropTypes.bool,
  option: PropTypes.object,
  isSelected: PropTypes.bool,
  isCreditMethod: PropTypes.bool,
};

function PaymentOption({
  option,
  hasChild,
  isSelected,
  isCreditMethod,
  onOpen,
}) {
  const { value, title, icons } = option;

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        transition: (theme) => theme.transitions.create("all"),
        ...(hasChild && {
          flexWrap: "wrap",
        }),
      }}
    >
      <FormControlLabel
        value={value}
        control={
          <Radio checkedIcon={<Iconify icon="eva:checkmark-circle-2-fill" />} />
        }
        label={title}
        sx={{ py: 2, pl: 2.5, flexGrow: 1, mr: 0 }}
      />

      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ position: "absolute", right: 20, top: 24 }}
      >
        {icons.map((icon) => (
          <Box component="img" key={icon} src={icon} />
        ))}
      </Stack>
      {/* 
      {isCreditMethod && (
        <Stack
          alignItems="flex-start"
          sx={{
            px: 3,
            width: 1,
          }}
        >
          <Button
            size="small"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={onOpen}
            sx={{ my: 3 }}
          >
            Add new card
          </Button>
        </Stack>
      )} */}
    </Paper>
  );
}

import { XPay } from "@xstak/xpay-element-stage";
import { Payment } from "./Payment";
function XPayPayment({ method, onClose, total, serviceFee,
  taxAmount }) {
  return (
    <XPay
      xpay={{
        publishableKey: process.env.REACT_APP_XPAY_PUBLISHABLE_KEY,
        accountId: process.env.REACT_APP_XPAY_ACCOUNT_ID,
        hmacSecret: process.env.REACT_APP_XPAY_HMAC_SECRET,
      }}
    >
      <Payment method={method} onClose={onClose} total={total} serviceFee={serviceFee}
        taxAmount={taxAmount} />
    </XPay>
  );
}

export default XPayPayment;

// import { XPAY } from '../config-global';
// import { XPay } from "@xstak/xpay-element-stage";
// import { Payment } from "./Payment";
// function XPayPayment({ method,placeOrder }) {
//   console.log(XPAY, 'XPAY');
//   console.log('process.env', process.env);
//   const xpay = {
//     publishableKey: 'xpay_pk_test_f6fd9f708caba38f86fa64f1247747ea8491038a8e39a67d8a600d435730cb19',
//     accountId: 'd84e334b61f242d1',
//     hmacSecret: '3bb2eeff0c349c1380c503bdf6ec6e17355a07cd9cf8aa3284643d9d2e3a417d'
//   };
//   return (
//     <XPay xpay={{ publishableKey: xpay.publishableKey, accountId: xpay.accountId, hmacSecret: xpay.hmacSecret }}>
//       <Payment method={method} placeOrder ={placeOrder } />
//     </XPay>
//   )
// }

// export default XPayPayment;

import React, { useEffect, useState, useMemo } from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import CartModalHeader from "./CartModalHeader";
import CartModalContent from "./CartModalContent";
import CartModalAction from "./CartModalAction";
import { useSelector } from "react-redux";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { useCart } from "../CartContext";
import { calculateAndRoundTax } from "src/utils/tax";
import QrScanner from "src/pages/qrcode/QrScanner";
import { useNavigate, useLocation } from "react-router-dom";

const CartItemModal = ({
  width,
  open,
  item,
  onClose,
  setOpenCarInformationModal,
  errorMessage
}) => {
  const navigate = useNavigate();

  const { tableData } = useSelector((state) => state.tableData);
  const { isLoading } = useSelector((state) => state.cardSlice);
  let mode = localStorage.getItem("mode");

  const { calculateSubTotal, calculateServiceFee } = useCart();
  const { cardItems } = useSelector((state) => state.cardSlice);

  const taxRate = cardItems?.data?.venueDetail?.configurations?.isPayingTax
    ? cardItems?.data?.venueDetail?.taxOnCash / 100
    : 0;

  let discount = 0;

  let subTotal = calculateSubTotal(cardItems?.data?.items);
  const taxAmount = calculateAndRoundTax(subTotal - discount, taxRate);

  const [isScanning, setIsScanning] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError ] = useState("");
  const [result, setResult] = useState("");

  const handleScan = (data) => {
    if (data) {
      setResult(data.text);
      setIsScanning(false);
      const lastPart = data.text.split("/").pop();
      const wholeText = data.text.split("/");
      if(wholeText[3] != "qrTable"){
        setError("Invalid QR Code")
      }else{
        onClose()
        const message = "ScannedLater";
          navigate(`/qrTable/${lastPart}`, { state: { message } });
          return 
        }
    }
  };

  const handleClose = () => {
    setIsScanning(false);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const serviceFee = useMemo(
    () =>
      cardItems?.data?.items?.length > 0
        ? calculateServiceFee(cardItems, subTotal, discount)
        : 0,
    [cardItems, subTotal, taxAmount]
  );
  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        {isLoading ? (
          <LoadingScreen width="100px" height="100px" marginT="0" h="50vh" />
        ) : isScanning ? (
          <>
            <QrScanner
              onScan={handleScan}
              onError={handleError}
              onClose={handleClose}
            />
          </>
        ) : (
          <>
           <DialogTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: {
                    xs: "flex-start", 
                    sm: "center",   
                  },
                  justifyContent: {
                    xs: "space-between", 
                    sm: "space-between",
                  },
                  flexDirection: {
                    xs: "column",  
                    sm: "row",     
                  },
                  gap: 0, 
                }}
              >
                <Typography
                  sx={{
                    color: "#F08203",
                    fontWeight: "700",
                    fontSize: "25px",
                    m: 0, 
                    p: 0, 
                  }}
                >
                  Cart
                </Typography>

                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: "600",
                    fontSize: "18px",
                    m: 0,
                    p: 0, 
                    mt: { xs: 1, sm: 0 },
                  }}
                >
                  {mode === "dineIn"
                    ? "DineIn"
                    : mode === "pickUp"
                    ? "PickUp"
                    : mode === "QrPickUp"
                    ? "QR PickUp"
                    : mode === "QrDineIn"
                    ? "QR DineIn"
                    : ""}{" "}
                  {(mode === "QrDineIn" || mode === "dineIn") &&
                  tableData &&
                  tableData.isAvailable
                    ? `(${tableData?.name})`
                    : ""}
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => onClose(!open)}
                  sx={{
                    position: {
                      xs: "absolute", 
                      sm: "static",
                    },
                    top: 8,
                    right: 8,
                  }}
                >
                  <Iconify
                    sx={{
                      height: 25,
                      width: 25,
                    }}
                    icon="mdi:close"
                    color="#fca92e"
                  />
                </IconButton>
              </Box>
            </DialogTitle>
            <Box>
              <Divider />
            </Box>
            <DialogContent sx={{ typography: "body2" }}>
              <CartModalContent taxAmount={taxAmount} serviceFee={serviceFee} />
            </DialogContent>
            <Box>
              <Divider />
            </Box>
            {(errorMessage || error) && (
              <Typography
                style={{
                  textAlign: 'center',
                  color: 'red', 
                  margin: '20px 0', 
                }}
              >
                {errorMessage? errorMessage : error}
              </Typography>
            )}
            <DialogActions sx={{ display: "block" }}>
              <CartModalAction
                open={open}
                onClose={onClose}
                taxAmount={taxAmount}
                serviceFee={serviceFee}
                setIsDisabled={setIsDisabled}
                isDisabled={isDisabled}
                setIsScanning={setIsScanning}
                setOpenCarInformationModal={setOpenCarInformationModal}
              />
            </DialogActions>
          </>
        )}
      </ConfirmDialog>
    </>
  );
};
export default CartItemModal;

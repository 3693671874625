import { useEffect, useCallback } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCartByCustomerId } from "src/redux/slices/cardSlice";
import { getActiveOrders } from "src/redux/slices/orderPlaceSlice";
import { useTheme } from "@mui/material/styles";
import Iconify from "src/components/iconify/Iconify";
import { useLocation } from "react-router";
import useSession from "src/utils/useSession";

const HeaderCart = ({ openCart, setOpenCart }) => {
  const navigate = useNavigate();
  const gotoAvtiveOrder = () => {
    navigate(`/active`);
  };
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.login);
  const { cardItems } = useSelector((state) => state.cardSlice);
  const { order } = useSelector((state) => state.order);

  const sessionInfo = useSession();

  const getCartItem = useCallback(async () => {
    try {
      await dispatch(getCartByCustomerId(sessionInfo?.sessionId));
      await dispatch(getActiveOrders(sessionInfo?.sessionId));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [cardItems]);

  const filteredOrders =
    order &&
    order.length > 0 &&
    order?.filter((item) =>
      ["pending", "processing", "finished", "completed"].includes(item.state)
    );
  const hasFilteredOrders = filteredOrders && filteredOrders.length > 0;

  useEffect(() => {
    getCartItem();
  }, []);

  return (
    <>
      {location?.pathname !== "/auth/login" &&
        location?.pathname !== "/auth/register" &&
        location?.pathname !== "/auth/reset-password" &&
        location?.pathname !== "/auth/verify" && (
          <>
            {isMediumScreen ? (
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {hasFilteredOrders && (
                  <Button
                    sx={{
                      color: "black",
                      display: "flex",
                      gap: "5px",
                      height: "42px",
                    }}
                    variant="contained"
                    target="_blank"
                    rel="noopener"
                    onClick={gotoAvtiveOrder}
                  >
                    Active order
                  </Button>
                )}
                <Button
                  sx={{
                    color: "black",
                    display: "flex",
                    gap: "5px",
                    height: "42px",
                  }}
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  onClick={() => setOpenCart(!openCart)}
                >
                  <Iconify icon="ph:bag" />
                  Cart -
                  {cardItems?.data?.items?.length > 0
                    ? cardItems?.data?.items?.length
                    : 0}
                </Button>
              </Box>
            ) : (
              <>
                {/* {isLoggedIn ? ( */}
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  {hasFilteredOrders && (
                    <Button
                      sx={{
                        color: "#FCA92E",
                        minWidth: "40px",
                        padding: "0",
                        display: "flex",
                        backgroundColor: "transparent",
                        marginRight: !isLoggedIn ? "10px" : "0px",
                        "&:hover": {
                          background: "none",
                        },
                      }}
                      variant="contained"
                      target="_blank"
                      rel="noopener"
                      onClick={gotoAvtiveOrder}
                    >
                      <Iconify
                        icon="mdi:truck-fast-outline"
                        style={{ height: "28px", width: "28px" }}
                      />
                    </Button>
                  )}
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      position: "relative",
                      marginTop: "4px",
                    }}
                  >
                    <Button
                      sx={{
                        color: "#FCA92E",
                        minWidth: "40px",
                        padding: "0",
                        display: "flex",
                        backgroundColor: "transparent",
                        marginRight: !isLoggedIn ? "10px" : "0px",
                        "&:hover": {
                          background: "none",
                        },
                      }}
                      variant="contained"
                      target="_blank"
                      rel="noopener"
                      onClick={() => setOpenCart(!openCart)}
                    >
                      <Iconify
                        icon="ph:bag"
                        style={{ height: "28px", width: "28px" }}
                      />
                    </Button>
                    {cardItems?.data?.items?.length > 0 && (
                      <Typography
                        component="div"
                        sx={{
                          position: "absolute",
                          left: "20px",
                          backgroundColor: "#FCA92E",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "13px",
                        }}
                      >
                        {cardItems?.data?.items?.length > 0
                          ? cardItems?.data?.items?.length
                          : 0}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {/* ) : null} */}
              </>
            )}
          </>
        )}
    </>
  );
};

export default HeaderCart;

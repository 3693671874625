import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cart, setCart] = useState([]);
  const [isCartOpen, setCartOpen] = useState(false);
  const { cardItems } = useSelector((state) => state.cardSlice);

  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedTip, setSelectedTip] = useState(
    cardItems?.data?.tip ? cardItems?.data?.tip : 0
  );

  useEffect(() => {
    if (cardItems?.data?.tip > 0) setSelectedTip(cardItems?.data?.tip);
  }, [cardItems]);

  const openCart = () => {
    setCartOpen(true);
  };

  const closeCart = () => {
    setCartOpen(false);
  };

  const toggleCart = () => {
    setCartOpen((prevOpen) => !prevOpen);
  };

  const selectVenue = (venue) => {
    setSelectedVenue(venue);
  };

  const addToCart = (item, quantity, selectedSauces, selectedAddOns) => {
    const existingCartItem = cart.find(
      (cartItem) => cartItem.title === item.title
    );

    if (existingCartItem) {
      const updatedCart = cart.map((cartItem) =>
        cartItem.title === item.title
          ? {
              ...cartItem,
              quantity: cartItem.quantity + quantity,
              selectedSauces: selectedSauces || cartItem.selectedSauces,
              selectedAddOns: selectedAddOns || cartItem.selectedAddOns,
            }
          : cartItem
      );

      setCart(updatedCart);
    } else {
      setCart([
        ...cart,
        {
          ...item,
          quantity,
          selectedSauces: selectedSauces || [],
          selectedAddOns: selectedAddOns || [],
        },
      ]);
    }
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.title !== itemId));
  };

  const handleTipChange = (tipValue) => {
    setSelectedTip(tipValue);
  };
  const resetCart = () => {
    setSelectedTip(0);
  };
  const formatPriceWithOut = (value) => {
    return Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const calculateMenuItemTotal = (cartItem) => {
    const itemQuantity = cartItem.qty;
    let p =
      cartItem?.choiceGroup?.length > 0 || cartItem?.variants?.length > 0
        ? cartItem?.priceWithChoiceGroup
        : cartItem?.price;
    let itemTotal = p * itemQuantity;
    if (cartItem.selectedAddOns && cartItem.selectedAddOns.length > 0) {
      cartItem.selectedAddOns.forEach((addon) => {
        itemTotal += parseFloat(addon.price.replace("Rs. ", ""));
      });
    }
    itemTotal = itemTotal.toFixed(0);
    return `Rs. ${itemTotal}`;
  };

  const calculateSubTotal = (cart) => {
    const grandTotal = cart.reduce((total, cartItem) => {
      const itemQuantity = cartItem.qty;
      let itemTotal =
        parseFloat(
          cartItem.priceWithChoiceGroup
            ? cartItem.priceWithChoiceGroup
            : cartItem.price
        ) * itemQuantity;
      if (cartItem.selectedAddOns && cartItem.selectedAddOns.length > 0) {
        cartItem.selectedAddOns.forEach((addon) => {
          itemTotal += parseFloat(addon.price.replace("Rs. ", ""));
        });
      }
      return total + itemTotal;
    }, 0);
    return `${grandTotal.toFixed(0)}`;
  };

  const calculateFinalTotal = (cart) => {
    const itemsTotal = cart.reduce((total, cartItem) => {
      const itemQuantity = cartItem.qty;
      let itemTotal =
        parseFloat(
          cartItem.priceWithChoiceGroup
            ? cartItem.priceWithChoiceGroup
            : cartItem.price
        ) * itemQuantity;

      if (cartItem.selectedAddOns && cartItem.selectedAddOns.length > 0) {
        cartItem.selectedAddOns.forEach((addon) => {
          itemTotal += parseFloat(addon.price.replace("Rs. ", ""));
        });
      }

      return total + itemTotal;
    }, 0);

    const grandTotal = itemsTotal + parseFloat(selectedTip || 0);
    return `${grandTotal.toFixed(0)}`;
  };

  const calculateServiceFee = (cardItems, subTotal, discount) => {
    if (cardItems?.data?.venueDetail?.configurations?.isServiceFees) {
      if (
        (cardItems?.data?.venueDetail?.serviceFeesObject?.type ===
          "Percentage" &&
          cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
            "Both") ||
        (cardItems?.data?.venueDetail?.serviceFeesObject?.type ===
          "Percentage" &&
          cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
            "Cash") ||
        (cardItems?.data?.venueDetail?.serviceFeesObject?.type ===
          "Percentage" &&
          cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
            "Card")
      ) {
        const calculatedServiceFee =
          ((subTotal - discount) *
            cardItems?.data?.venueDetail.serviceFeesObject?.amount) /
          100;
        return calculatedServiceFee;
      } else {
        return cardItems?.data?.venueDetail.serviceFeesObject?.amount;
      }
    }
    return 0;
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        isCartOpen,
        openCart,
        closeCart,
        toggleCart,
        selectVenue,
        selectedVenue,
        selectedTip,
        handleTipChange,
        calculateMenuItemTotal,
        calculateFinalTotal,
        calculateSubTotal,
        resetCart,
        calculateServiceFee,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}

import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  TextField,
  Autocomplete,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormProvider, { RHFTextField } from "../../../components/hook-form";
import { useDispatch } from "react-redux";
// import { countries } from "src/assets/data";
import { useNavigate } from "react-router";
import {
  getProfile,
  updateUserEmailOrPhone,
} from "src/redux/slices/loginSlice";
import { useSelector } from "react-redux";
import { useSnackbar } from "../../../components/snackbar";
// import VerifiedIcon from '@mui/icons-material/Verified';
import VerifyCodePage from "src/pages/auth/VerifyCodePage";
import { mt } from "date-fns/locale";
import Iconify from "src/components/iconify/Iconify";
import VerificationDialog from "./VerificationDialog";

const CountryCode = process.env.REACT_APP_COUNTRY_CODE;

export default function UpdateUserEmailOrPhone({ context }) {
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    if (user?.id) {
      dispatch(getProfile(user?.id));
    }
  }, [dispatch, user?.id]);
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
        "Invalid Email Format"
      ),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
  });

  const defaultValues = {
    email: user?.email || "",
    phone: user?.phone || "",
    // countryCode: user?.countryCode || countries?.find(country => country.code === "PK"),
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (context === "CartCheckoutSummary") {
      try{
        const userData = {
          isPhone: "true",
          ...data,
        };
        await dispatch(updateUserEmailOrPhone(userData, user?.id));
        setOpenModal(true);
    } catch (error){
      setErrorMsg(error?.response?.data?.message)
    }
    } else {
      try {
        const userDataToSend = {
          isEmail: "false",
          isPhone: "true",
          ...data,
        };
        await dispatch(updateUserEmailOrPhone(userDataToSend, user?.id));
        let queryParams = new URLSearchParams();
        queryParams.append("verify", "Phone");
        const profilePath = "profile";
        navigate(`/auth/verify?${queryParams.toString()}`, {
          state: { profilePath },
        });
      } catch (error) {
        enqueueSnackbar(`${error?.response?.data?.message}`, {
          variant: "error",
        });
        reset();
        setError("afterSubmit", {
          ...error,
          message: error?.response?.data?.message,
        });
      }
    }
  };

  const handleModalClose = () => setOpenModal(false);
  return (
    <>
      <Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} mt={4}>
            <Box display="flex" alignItems="center">
              <RHFTextField
                name="email"
                label="Email"
                disabled={!!user?.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Iconify
                          icon={
                            user?.isEmailVerified
                              ? "eva:checkmark-circle-fill"
                              : "eva:clock-outline"
                          }
                          style={{
                            width: 20,
                            height: 20,
                            color: user?.isEmailVerified
                              ? "#36B37E"
                              : "#FFAB00",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Stack sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <RHFTextField
                sx={{ borderRadius: "transparent" }}
                name="phone"
                label="Phone number"
                placeholder="3xxxxxxxxx"
                error={Boolean(errors.phone) || Boolean(errorMsg)}
                helperText={errors.phone ? errors.phone.message : errorMsg || ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>{CountryCode}</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Iconify
                          icon={
                            user?.isPhoneVerified
                              ? "eva:checkmark-circle-fill"
                              : "eva:clock-outline"
                          }
                          style={{
                            width: 20,
                            height: 20,
                            color: user?.isPhoneVerified
                              ? "#36B37E"
                              : "#FFAB00",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputMode: "numeric",
                }}
                inputProps={{
                  maxLength: 10,
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                    setErrorMsg("")
                  },
                  onKeyDown: (e) => {
                    if (
                      !/^[0-9]$/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Tab"
                    ) {
                      e.preventDefault();
                    }
                  },
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <LoadingButton
              color="inherit"
              size="large"
              type="submit"
              variant="contained"
              sx={{
                width: "15%",
                marginTop: "15px",
                bgcolor: "#FCA92E",
                color: (theme) =>
                  theme.palette.mode === "light" ? "#000000" : "grey.800",
                "&:hover": {
                  bgcolor: "text.primary",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                },
              }}
            >
              Update
            </LoadingButton>
          </Stack>
        </FormProvider>
        {context === "CartCheckoutSummary" && (
          <VerificationDialog open={openModal} onClose={handleModalClose} />
        )}
      </Box>
    </>
  );
}

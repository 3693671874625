import React, { useCallback, useEffect } from "react";
import { useCart } from "../CartContext";

import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  removeItemFromCart,
  updateItemsToCard,
  setPickUpAndDineIn,
} from "src/redux/slices/cardSlice";
import { fetchImage } from "src/utils/fetchImage";
import Image from "src/components/image/Image";
import useSession from "src/utils/useSession";
import { CustomTextFieldForCart } from "src/components/custom-input/CustomTextField";
import VenueItmeModalEdit from "../Venue Profiles/venueModalEdit/VenueItmeModalEdit";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";

const CartModalContent = ({ taxAmount, serviceFee }) => {
  const sessionInfo = useSession();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cardItems } = useSelector((state) => state.cardSlice);
  const {
    selectedTip,
    handleTipChange,
    calculateSubTotal,
    calculateMenuItemTotal,
  } = useCart();
  const [openConfirm1, setOpenConfirm1] = useState(false);
  const [orderItem, setOrderItem] = useState(null);
  const handleMenuItemClick = (item) => {
    setOpenConfirm1(true);
    setOrderItem(item);
  };

  const closeModal = () => {
    setOpenConfirm1(false);
  };

  const handleRemoveFromCart = async (item) => {
    await dispatch(
      removeItemFromCart(
        cardItems?.data?.id,
        item?.cartItemId,
        sessionInfo?.sessionId,
        item?.venueId,
        item?.levelId
      )
    );
    if (cardItems?.data?.items.length === 1) {
      dispatch(
        setPickUpAndDineIn({
          isPickUp: false,
          isDineIn: false,
        })
      );
      handleTipChange(null);
      navigate(`/venue/${cardItems?.data?.venueId}`);
      return;
    }
  };

  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    const fetchImagesForCart = async () => {
      if (cardItems?.data?.items && cardItems?.data?.items.length > 0) {
        const urls = await Promise.all(
          cardItems?.data?.items.map(async (elem) => {
            try {
              const res = await fetchImage(elem.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForCart();
  }, [cardItems]);

  const updateItemFromCardAddByOne = useCallback(async (item) => {
    try {
      const updatedItem = {
        ...item,
        qty: item.qty + 1,
      };
      await dispatch(updateItemsToCard(updatedItem, sessionInfo?.sessionId));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const updateItemFromCardDecByOne = useCallback(async (item) => {
    try {
      const updatedItem = {
        ...item,
        qty: item.qty - 1,
      };
      await dispatch(updateItemsToCard(updatedItem, sessionInfo?.sessionId));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const tipOptions = [
    { label: "Rs. 0", value: 0 },
    { label: "Rs. 50", value: 50 },
    { label: "Rs. 100", value: 100 },
  ];

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box>
      {cardItems?.data?.items?.map((cartItem, index) => {
        return (
          <>
            <Box
              key={index}
              sx={{
                display: "flex",
                padding: "10px 0",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Image
                  src={
                    imageURLs[index]
                      ? imageURLs[index]
                      : "/assets/placeholder.png"
                  }
                  sx={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "5px",
                    "@media (max-width: 600px)": {
                      height: "50px",
                      width: "50px",
                    },
                  }}
                />

                <Stack>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "self-end",
                    }}
                  >
                    <Typography
                      onClick={() => handleMenuItemClick(cartItem)}
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        marginLeft: "9px",
                        cursor: "pointer",
                      }}
                    >
                      {cartItem?.name}
                    </Typography>
                    <Iconify
                      onClick={() => handleMenuItemClick(cartItem)}
                      icon="mdi:pencil-outline"
                      sx={{
                        marginLeft: "5px",
                        color: "#F08203",
                        cursor: "pointer",
                        width: "16px",
                      }}
                    />
                  </Box>

                  {cartItem?.selectedVariant && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          ml: 1.3,
                          fontSize: 12,
                          fontWeight: "bold",
                          color: "#666",
                        }}
                      >
                        Variant:
                      </Typography>
                      <Typography sx={{ ml: 1, fontSize: 12 }}>
                        {cartItem?.selectedVariant?.name}
                      </Typography>
                    </Box>
                  )}

                  {cartItem?.groups && cartItem?.groups?.length > 0 && (
                    <>
                      {cartItem?.groups?.map((sauce) => (
                        <Typography
                          key={index}
                          variant="caption"
                          sx={{
                            color: "#666",
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            marginLeft: "10px",
                            gap: "2px",
                            fontWeight: "bold",
                            "@media (max-width: 600px)": {
                              fontSize: "10px",
                              marginLeft: "5px",
                              gap: "1px",
                            },
                          }}
                        >
                          + {sauce?.name} :
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#666",
                              display: "flex",
                              marginLeft: "5px",
                              gap: "2px",
                              flexWrap: "wrap",
                              "@media (max-width: 600px)": {
                                fontSize: "10px",
                                marginLeft: "2px",
                                gap: "1px",
                              },
                            }}
                          >
                            {sauce?.items?.map((sauceItem, sauceIndex) => (
                              <span key={sauceIndex}>
                                {sauceItem?.item}
                                {` (Rs. ${sauceItem.price * cartItem.qty})`}
                                {sauceIndex !== sauce?.items?.length - 1 &&
                                  ", "}
                              </span>
                            ))}
                          </Typography>
                        </Typography>
                      ))}
                    </>
                  )}
                  <Typography
                    variant="caption"
                    sx={{
                      color: "red",
                      display: "flex",
                      marginLeft: "10px",
                      gap: "2px",
                      "@media (max-width: 600px)": {
                        fontSize: "10px",
                      },
                    }}
                  >
                    <span>{cartItem?.notes}</span>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#F08203",
                      marginTop: "4px",
                      fontSize: "16px",
                      marginLeft: "10px",
                      "@media (max-width: 1440px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    {calculateMenuItemTotal(cartItem)}
                  </Typography>
                </Stack>
              </Box>

              <Button
                sx={{
                  height: "30px",
                  width: "130px",
                  borderRadius: "20px",
                  border: "2px solid #F08203",
                  marginRight: "10px",
                  "@media (max-width: 600px)": {
                    width: "auto",
                    height: "25px",
                    marginRight: "5px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {cartItem.qty > 1 ? (
                    <Button
                      onClick={() => updateItemFromCardDecByOne(cartItem)}
                    >
                      <Iconify
                        sx={{
                          color: "#F08203",
                        }}
                        icon={"ic:baseline-minus"}
                      />
                    </Button>
                  ) : (
                    <Button onClick={() => handleRemoveFromCart(cartItem)}>
                      <Iconify
                        sx={{
                          color: "#F08203",
                        }}
                        icon={"mdi:trash-outline"}
                      />
                    </Button>
                  )}
                  <Typography sx={{ fontSize: "16px", color: "#F08203" }}>
                    {cartItem.qty}
                  </Typography>
                  <Button onClick={() => updateItemFromCardAddByOne(cartItem)}>
                    <Iconify
                      sx={{
                        color: "#F08203",
                      }}
                      icon={"ic:baseline-plus"}
                    />
                  </Button>
                </Box>
              </Button>
            </Box>
            <Divider
              sx={{
                marginRight: "13px",
                marginLeft: "8px",
                marginTop: "10px",
                color: "black",
              }}
            />
          </>
        );
      })}

      {cardItems?.data?.items?.length > 0 && (
        <Box>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "14px",
              marginTop: "20px",
            }}
          >
            <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
              Sub Total
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
              Rs. {calculateSubTotal(cardItems?.data?.items)}
            </Typography>
          </Stack>

          {cardItems?.data?.venueDetail?.configurations?.isServiceFees &&
            (cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
              "Cash" ||
              cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
                "Both") && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "14px",
                  marginTop: "-12px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  Service Fee
                  <IconButton>
                    <Iconify
                      sx={{
                        height: "14px",
                        width: "14px",
                      }}
                      icon={"ph:info"}
                    />{" "}
                  </IconButton>
                </Typography>
                <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                  Rs. {serviceFee}
                </Typography>
              </Stack>
            )}

          {cardItems?.data?.venueDetail?.configurations?.isPayingTax && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "14px",
                marginTop: "-12px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Tax
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Rs. {taxAmount ? taxAmount : 0}
              </Typography>
            </Stack>
          )}

          <Divider
            sx={{
              marginRight: "13px",
              marginLeft: "8px",
              marginTop: "10px",
              color: "black",
            }}
          />
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "14px",
              marginTop: "12px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                fontWeight: "500",
                fontSize: "15px",
              }}
            >
              Tip
            </Typography>
            <Box
              sx={{
                fontWeight: "500",
                fontSize: "5px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "3px",
                }}
              >
                {tipOptions.map((tipOption) => (
                  <Button
                    key={tipOption.value}
                    variant={
                      selectedTip === tipOption.value ? "contained" : "outlined"
                    }
                    sx={{
                      marginLeft: "8px",
                      height: "30px",
                      ...(isMobile && {
                        fontSize: "13px",
                      }),
                    }}
                    onClick={() => handleTipChange(tipOption.value)}
                  >
                    {tipOption.label}
                  </Button>
                ))}
              </Stack>

              <CustomTextFieldForCart
                type="number"
                id="outlined-basic"
                variant="outlined"
                placeholder="custom tip"
                value={selectedTip}
                onChange={(e) => handleTipChange(e.target.value)}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
        </Box>
      )}

      {openConfirm1 && (
        <VenueItmeModalEdit
          width="md"
          open={openConfirm1}
          setOpenConfirm1={setOpenConfirm1}
          onClose={closeModal}
          item={orderItem}
          data={cardItems?.data}
        />
      )}
    </Box>
  );
};

export default CartModalContent;

import { useState } from "react";
import axios from "axios";
import { Box, Typography, Button } from "@mui/material";
import Image from "src/components/image/Image";
import { socket } from 'src/App'

const Processing = ({ detail, toggleContent, setToggleContent, onClose, onCancelSuccess }) => {
  const [loading, setLoading] = useState(false);

  const handleCancelOrder = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart/changeOrderState`,
        {
          orderId: detail?._id,
          orderStateChangeTime: {}, 
          state: "cancelled", 
        }
      );
      if(response?.status == 200){
        socket.emit("IncomingOrderCancel", { order: { ...response.data, isSuperAdmin: false } });
      }
      if(response.data.orderType=='QrDineIn'){
      const table = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/posCart/freeTable`, {
        tableId: response.data.tableId,
        orderId: response.data.id,
      });
      socket.emit("tableFreed", { table: { ...table.data , isSuperAdmin: false } });
      }
      onCancelSuccess(detail._id);
      onClose();
    } catch (error) {
      console.error("Error cancelling order:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Processing your order <br />
        <Box mt={3} mb={1}>
          <Image
            src="/assets/order/processingProgress.svg"
            alt="image not exis"
            width="100%"
          />
        </Box>
        <Typography sx={{ margin: "0px" }}>
          Waiting for venue to accept your order
        </Typography>
        <span style={{ color: "#fda92d", fontSize: "14px" }}>
          Order #{detail?._id?.replace(/^.{18}/, "OD")}
        </span>
      </Typography>

      <Box
        mt={3}
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image
          src="/assets/order/processing.gif"
          sx={{ width: "50%" }}
          alt="image not exis"
        />
      </Box>

      <Box mt={2} mb={2}>
        <Button
          onClick={() => setToggleContent(!toggleContent)}
          type="button"
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            height: "40px",
            fontSize: "16px",
          }}
        >
          {toggleContent ? "Hide order details" : "show order details"}
        </Button>
      </Box>

      <Box mt={1} mb={4}>
        <Button
          type="button"
          onClick={handleCancelOrder}
          //   variant="contained"
          color="primary"
          disabled={loading}
          sx={{
            width: "100%",
            height: "40px",
            fontSize: "16px",
          }}
        >
          {loading ? "Cancelling..." : "Cancel Order"}
          </Button>
      </Box>
    </Box>
  );
};
export default Processing;

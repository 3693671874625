import React, { useState, useMemo } from "react";
import { PaymentElement, useXpay } from "@xstak/xpay-element-stage";
import api from "src/utils/axios";
import { useCart } from "./Venue/CartContext";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";
import { useSnackbar } from "../components/snackbar";
import { socket } from "src/App";
export const Payment = ({ method, onClose, total, serviceFee,
  taxAmount }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { cardItems } = useSelector((state) => state.cardSlice);
  const { selectedTip, calculateSubTotal } = useCart();
  const { user, isLoggedIn } = useSelector((state) => state.login);
  let mode = localStorage.getItem("mode");
  const guestUserData = JSON.parse(localStorage.getItem("guestUser"));
  const { tableData } = useSelector((state) => state.tableData);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [binDetails, setBinDetails] = useState(null);
  const [filledAllCardDetails, setFilledAllCardDetails] = useState(false);

  const subTotal = useMemo(
    () =>
      cardItems?.data?.items?.length > 0
        ? calculateSubTotal(cardItems.data.items)
        : 0,
    [cardItems]
  );

  const options = {
    override: true,
    fields: {
      creditCard: {
        placeholder: "4234 1234 1234 1234",
        label: "Enter your credit card",
      },
      exp: {
        placeholder: "Exp. Date",
      },
    },
    style: {
      ".input": {
        border: "1px solid black",
        "border-radius": "15px",
      },
      ".invalid": {},
      ".label": {
        "font-size": "18px",
      },
      ":focus": {},
      ":hover": {},
      "::placeholder": {},
      "::selection": {},
    },
  };
  const xpay = useXpay();
    const pay = async () => {
    let totalServiceValue = 0;

    if (
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
      "Both" &&
      (method === "cash" || method === "card")
    ) {
      totalServiceValue = Number(serviceFee);
    } else if (
      method === "cash" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Cash"
    ) {
      totalServiceValue = Number(serviceFee);
    } else if (
      method === "card" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Card"
    ) {
      totalServiceValue = Number(serviceFee);
    }
    try {
      setIsLoading(true);
      const customer1 =
        mode === "dineIn" && guestUserData
          ? guestUserData
          : { name: `${user?.firstName} ${user?.lastName}`, email: user.email };
      const shipping = {
        address1: "abc",
        city: "Islamabad",
        country: "Pakistan",
        phone: "03415555555",
        zip: "12345",
        shipping_method: "Standard",
      };
      const paymentIntentResponse = await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/payment/createIntent`,
        {
          amount: total,
          currency: "PKR",
          payment_method_types: "card",
          customer: customer1,
          shipping: shipping,
          customerId: user?.id,
          levelId: cardItems?.data?.levelId,
          venueId: cardItems?.data?.venueId,
          total: total,
          subTotal: Number(subTotal),
          type: "web",
          sessionId: cardItems?.data?.sessionId,
          orderType: mode,
          tableId: tableData?.id,
          tip:selectedTip === null ? 0 : selectedTip,
          paymentType: method,
          tax: taxAmount,
          serviceFees: totalServiceValue
        }
      );
      console.log(
        "res.success === true",
        paymentIntentResponse.data.response.success === true
      );

      if (paymentIntentResponse.data.response.success === true) {
        const { pi_client_secret, encryptionKey } =
          paymentIntentResponse.data.response.data;
        try {
          const { message, error } = await xpay.confirmPayment(
            "card",
            pi_client_secret,
            customer1,
            encryptionKey
          );
          if (error) {
            setIsLoading(false);
            enqueueSnackbar("Payment Failed!");
            throw new Error(`Payment failed!: ${error}`);
          } else {
            const updateOrderResponse = await api.post(
              `${process.env.REACT_APP_BACKEND_URL}/cart/updateOrderStateOnPayment`,
              {
                orderId: paymentIntentResponse.data.order.id,
                cartId: paymentIntentResponse.data.order.cartId,
                paymentIntent: paymentIntentResponse.data.response.data._id,
                transactionId: paymentIntentResponse.data.transaction.id,
              }
            );

            if (updateOrderResponse.error) {
              setIsLoading(false);
              enqueueSnackbar("Payment Failed!", { variant: "error" });
              throw new Error(
                `Order update failed: ${updateOrderResponse.error}`
              );
            } else {
              setIsLoading(false);
              socket.emit("addIncomingOrder", { order: updateOrderResponse.data });
              if (updateOrderResponse.data.orderType == "QrDineIn") {
                socket.emit("addToTables", {
                  table: { ...updateOrderResponse.data.tableId, isSuperAdmin: false },
                });
              }
              // onClose();
              navigate("/active");
            }
          }
        } catch (error) {
          await api.post(
            `${process.env.REACT_APP_BACKEND_URL}/payment/paymentFailed`,
            {
              orderId: paymentIntentResponse.data.order.id,
              cartId: paymentIntentResponse.data.order.cartId,
              transactionId: paymentIntentResponse.data.transaction.id,
              message: error.message
            }
          );
          enqueueSnackbar("Payment Failed!", { variant: "error" });
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        enqueueSnackbar("Payment Failed!", { variant: "error" });
        throw new Error("Payment Intent creation failed");
      }
    } catch (error) {
      enqueueSnackbar("Payment Failed!", { variant: "error" });
      console.error("Error during payment process:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box p={2}>
        {" "}
        <PaymentElement
          options={options}
          onBinDiscount={(event) => {
            setBinDetails(event.data);
            console.log("bin details", event);
          }}
          onReady={(event) => {
            setFilledAllCardDetails(event.ready);
            console.log("ready event", event);
          }}
        />
        {filledAllCardDetails && (
          <LoadingButton
            fullWidth
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
            onClick={pay}
            disabled={!user?.isPhoneVerified && isLoggedIn}
            sx={{
              bgcolor: "#fda92d",
              color: (theme) =>
                theme.palette.mode === "black" ? "common." : "grey.800",
              "&:hover": {
                bgcolor: "#B66816",
                color: (theme) =>
                  theme.palette.mode === "black" ? "common.white" : "grey.800",
              },
            }}
          >
            Pay Now
          </LoadingButton>
        )}
      </Box>
    </>
  );
};

import { Box, Typography, Divider, IconButton, Grid } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import Image from "src/components/image/Image";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useState, useEffect } from "react";
import { fetchImage } from "src/utils/fetchImage";
import { useCart } from "../CartContext";

const Ordermodal = ({ detail, width, open, onClose }) => {
  const [imageURLs, setImageURLs] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const { calculateMenuItemTotal } = useCart();
  const [venueImageURL, setVenueImageURL] = useState("");

  useEffect(() => {
    const fetchVenueImage = async () => {
      if (detail?.venueId?.photoURL) {
        try {
          const res = await fetchImage(detail.venueId.photoURL);
          setVenueImageURL(res);
        } catch (error) {
          console.error("Error fetching venue image:", error);
        }
      }
    };

    fetchVenueImage();
  }, [detail?.venueId?.photoURL]);

  useEffect(() => {
    const fetchItemImages = async () => {
      if (detail?.items) {
        try {
          const imagePromises = detail.items.map((item) =>
            fetchImage(item?.photoURL)
          );
          const fetchedImages = await Promise.all(imagePromises);
          setImageURLs(fetchedImages);
        } catch (error) {
          console.error("Error fetching item images:", error);
        }
      }
    };

    if (open) {
      fetchItemImages();
    }
  }, [detail?.items, open]);

  // const [imageURLs, setImageURLs] = useState([]);
  // const [imageURL, setImageURL] = useState("");

  // const getImage = useCallback(
  //   async (item) => {
  //     try {
  //       let res = await fetchImage(item);
  //       setImageURLs((prevURLs) => [...prevURLs, res]);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   },
  //   [detail]
  // );

  // const getImage1 = useCallback(
  //   async (item) => {
  //     try {
  //       let res = await fetchImage(item);
  //       setImageURL(res);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   },
  //   [detail]
  // );

  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle sx={{ paddingBottom: "0" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Image
                src={venueImageURL ? venueImageURL : "/assets/placeholder.png"}
                alt={"image"}
                style={{
                  height: "100px",
                  width: "140px",
                  borderRadius: "5px",
                }}
              />
              <Box variant="h2">
                {detail?.venueId?.name}
                <Typography sx={{ fontWeight: 600 }}>
                  Order # {detail?._id?.replace(/^.{18}/, "OD")}
                </Typography>
              </Box>
            </Box>
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box mt={1}>
          <Divider />
        </Box>
        <DialogContent sx={{ typography: "body2" }}>
          <Box>
            {detail?.items?.map((item, index) => {
              const isLastItem = index === detail.items.length - 1;
              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    mb={1}
                    mt={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Image
                        src={
                          imageURLs[index]
                            ? imageURLs[index]
                            : "/assets/placeholder.png"
                        }
                        alt={"image"}
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "5px",
                        }}
                      />
                      <Box>
                        <Box sx={{ fontSize: "14px" }}>
                          {" "}
                          {item?.qty} X {item?.name}{" "}
                        </Box>
                        {item?.groups && item?.groups?.length > 0 && (
                          <>
                            {item?.selectedVariant && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: "#666",
                                  }}
                                >
                                  Variant:
                                </Typography>
                                <Typography sx={{ ml: 1, fontSize: 12 }}>
                                  {item?.selectedVariant?.name}
                                </Typography>
                              </Box>
                            )}
                            <>
                              {item?.groups?.map((sauce) => (
                                <Typography
                                  key={index}
                                  variant="caption"
                                  sx={{
                                    color: "#666",
                                    display: "flex",
                                    gap: "2px",
                                    fontWeight: "bold",
                                    "@media (max-width: 600px)": {
                                      fontSize: "10px",
                                    },
                                  }}
                                >
                                  {sauce?.name} :
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "#666",
                                      display: "flex",
                                      marginLeft: "5px",
                                      gap: "2px",
                                      "@media (max-width: 600px)": {
                                        fontSize: "10px",
                                      },
                                    }}
                                  >
                                    {sauce?.items?.map((item, index) => (
                                      <span key={index}>
                                        {item?.item}
                                        {index !== sauce.items.length - 1 &&
                                          ", "}
                                      </span>
                                    ))}
                                  </Typography>
                                </Typography>
                              ))}
                            </>
                          </>
                        )}

                        <Box sx={{ fontSize: "14px", color: "red" }}>
                          {item?.notes}{" "}
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ color: "black" }}>
                      {" "}
                      {calculateMenuItemTotal(item)}
                    </Box>
                  </Box>
                  {!isLastItem && <Divider />}
                </>
              );
            })}
          </Box>
        </DialogContent>
        <Divider />

        <DialogActions sx={{ paddingTop: "8px !important" }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Sub Total
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  mt: 0,
                  color: "black",
                }}
                align="right"
              >
                Rs: {detail?.subTotal}
              </Typography>
            </Grid>

            {detail?.serviceFees > 0 && (
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                  Service Fee
                </Typography>
              </Grid>
            )}

            {detail?.serviceFees > 0 && (
              <Grid item xs={6}>
                <Typography
                  sx={{ fontWeight: "500", fontSize: "14px", color: "black" }}
                  align="right"
                >
                  Rs: {detail?.serviceFees}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Tip
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "black",
                }}
                align="right"
              >
                Rs. {detail?.tip}
              </Typography>
            </Grid>

            {detail?.tax > 0 && (
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                  Tax
                </Typography>
              </Grid>
            )}
            {detail?.tax > 0 && (
              <Grid item xs={6}>
                <Typography
                  sx={{ fontWeight: "500", fontSize: "14px", color: "black" }}
                  align="right"
                >
                  Rs: {detail?.tax}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6}>
              <Typography
                sx={{ fontWeight: "500", fontSize: "15px", fontWeight: "bold" }}
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "black",
                }}
                align="right"
              >
                Rs: {detail?.total}
              </Typography>
            </Grid>
          </Grid>
          {/* <OrderProcessingStepper/> */}
        </DialogActions>
      </ConfirmDialog>
    </>
  );
};
export default Ordermodal;

import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { getWithChoiceGroup } from "src/redux/slices/choiceByGroupSlice";
import ChoiceGroupItems from "./ChoiceGroup";
import Variants from "./Variants";
import { Stack } from "@mui/system";
const VenueModalContent = ({
  item,
  selectedSauces,
  toggleSauce,
  notes,
  setNotes,
  selectedVariant,
  toggleVariantSelect
}) => {
  const [error, setError] = useState("");
  const { isLoading, choiceByGroup } = useSelector(
    (state) => state.choiceByGroup
  );



  const dispatch = useDispatch();
  const getChoiceGroup = useCallback(async () => {
    try {
      await dispatch(getWithChoiceGroup(item?.id));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [item]);

  useEffect(() => {
    getChoiceGroup();
  }, []);

  const handleNotesChange = (input) => {
    const words = input.split(" ");
    const wordTooLong = words.some((word) => word.length > 20);
    const tooManyWords = words.length > 20;
    if (wordTooLong) {
      setError("Each word should not exceed 20 characters.");
    } else if (tooManyWords) {
      setError("You can only add up to 20 words.");
    } else {
      setError("");
    }
    if (!wordTooLong && !tooManyWords) {
      setNotes(words.join(" "));
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen width="70px" height="70px" marginT="20px" h="0" />
      ) : (
        <Stack>
          <Variants variants={choiceByGroup} selectedVariant={selectedVariant} selectedSauces={selectedSauces} toggleSauce={toggleSauce} toggleVariantSelect={toggleVariantSelect} />
          <ChoiceGroupItems choiceByGroup={choiceByGroup} selectedSauces={selectedSauces} toggleSauce={toggleSauce} />
        </Stack>
      )}

      <Box>
        <TextField
          placeholder="Any dietary / special instruction (optional)"
          variant="outlined"
          multiline={true}
          rows={1}
          value={notes}
          onChange={(e) => handleNotesChange(e.target.value)}
          error={!!error}
          helperText={error || ""}
          sx={{
            marginTop: "10px",
            width: "100%",
          }}
        />
      </Box>
    </>
  );
};

export default VenueModalContent;

import { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import { useCart } from "../CartContext";
import { useState, useCallback, useRef } from "react";
import CartCheckoutTotalSummary from "./CartCheckoutTotalSummary";
import Iconify from "src/components/iconify";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { fetchImage } from "src/utils/fetchImage";
import { PaymentMethods } from "src/sections/payment";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import useSession from "src/utils/useSession";
import { placeOrderFromCart } from "src/redux/slices/orderPlaceSlice";
import { getCartByCustomerId } from "src/redux/slices/cardSlice";
import { fetchTableData } from "src/redux/slices/tableSlice";
import { socket } from "src/App";
import api from "src/utils/axios";
import { calculateAndRoundTax } from "src/utils/tax";
import UpdateUserEmailOrPhone from "../userProfile/updateEmailOrPhone";
import { clearTableData } from "src/redux/slices/tableSlice";

const CartCheckoutSummary = () => {
  const {
    calculateMenuItemTotal,
    resetCart,
    calculateSubTotal,
    selectedTip,
    calculateServiceFee,
    calculateFinalTotal,
  } = useCart();
  const { isLoading, cardItems } = useSelector((state) => state.cardSlice);
  const navigate = useNavigate();
  const handleMenuPage = () => {
    navigate(`/venue/${cardItems?.data?.venueId}`);
  };
  const [imageURLs, setImageURLs] = useState([]);

  const [method, setMethod] = useState("cash");
  const [error, setError] = useState("");

  const [total, setTotal] = useState(0);
  const [venuePermissions, setVenuePermissions] = useState();
  const [cashAvailable, setCashAvailable] = useState(true);
  const isMounted = useRef(false);

  const taxRate = cardItems?.data?.venueDetail?.configurations?.isPayingTax
    ? method === "cash"
      ? cardItems?.data?.venueDetail?.taxOnCash / 100
      : method === "card"
      ? cardItems?.data?.venueDetail?.taxOnCard / 100
      : 0
    : 0;

  let discount = 0;

  const subTotal = useMemo(
    () =>
      cardItems?.data?.items?.length > 0
        ? calculateSubTotal(cardItems.data.items)
        : 0,
    [cardItems]
  );
  const taxAmount = useMemo(
    () => calculateAndRoundTax(subTotal, taxRate),
    [subTotal, taxRate]
  );
  const serviceFee = useMemo(
    () =>
      cardItems?.data?.items?.length > 0
        ? calculateServiceFee(cardItems, subTotal, discount)
        : 0,
    [cardItems, subTotal, total]
  );
  const t = useMemo(
    () =>
      cardItems?.data?.items?.length > 0
        ? Number(calculateFinalTotal(cardItems?.data?.items)) +
          Number(taxAmount)
        : 0,
    [cardItems, serviceFee, taxAmount, selectedTip]
  );

  useEffect(() => {
    let updatedTotal = Number(t);

    if (
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
        "Both" &&
      (method === "cash" || method === "card")
    ) {
      updatedTotal += Number(serviceFee);
    } else if (
      method === "cash" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Cash"
    ) {
      updatedTotal += Number(serviceFee);
    } else if (
      method === "card" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Card"
    ) {
      updatedTotal += Number(serviceFee);
    }

    setTotal(updatedTotal);
  }, [
    method,
    serviceFee,
    cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption,
    selectedTip,
  ]);

  useEffect(() => {
    if (isMounted.current) {
      if (cardItems?.length < 1 || cardItems?.data?.items?.length < 0) {
        navigate(`/`);
      }
    } else {
      isMounted.current = true;
    }
  }, [cardItems]);

  useEffect(() => {
    let response;
    const fetchVenues = async () => {
      try {
        response = await api.get(
          `${process.env.REACT_APP_BACKEND_URL}/venues/getVenuePermission/${cardItems?.data?.venueId}`
        );
        setVenuePermissions(response.data);
        let orderType = localStorage.getItem("mode");
        if (
          orderType == "QrPickUp" &&
          !response?.data.configurations?.isCashAvailableOnVenueScan
        ) {
          setMethod("card");
          setCashAvailable(false);
        }
        if (
          orderType == "QrDineIn" &&
          !response?.data.configurations?.isCashAvailableOnTableScan
        ) {
          setMethod("card");
          setCashAvailable(false);
        }
      } catch (error) {
        console.log("Error Occured", error);
      }
    };
    fetchVenues();
  }, [cardItems]);

  useEffect(() => {
    const fetchImagesForOrders = async () => {
      if (cardItems && cardItems?.data?.items?.length > 0) {
        const urls = await Promise.all(
          cardItems?.data?.items.map(async (elem) => {
            try {
              const res = await fetchImage(elem.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForOrders();
  }, [cardItems]);

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  let orderType = localStorage.getItem("mode");
  const sessionInfo = useSession();
  const dispatch = useDispatch();

  const { tableData } = useSelector((state) => state.tableData);

  const { user, isLoggedIn } = useSelector((state) => state.login);
  const [isLoadings, setIsLoading] = useState(false);

  const placeOrder = useCallback(async () => {
    const guestUserData = JSON.parse(localStorage.getItem("guestUser"));
    let totalServiceValue = 0;

    if (
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption ===
        "Both" &&
      (method === "cash" || method === "card")
    ) {
      totalServiceValue = Number(serviceFee);
    } else if (
      method === "cash" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Cash"
    ) {
      totalServiceValue = Number(serviceFee);
    } else if (
      method === "card" &&
      cardItems?.data?.venueDetail?.serviceFeesObject?.paymentOption === "Card"
    ) {
      totalServiceValue = Number(serviceFee);
    }
    try {
      setIsLoading(true);
      let response = await dispatch(
        placeOrderFromCart(
          { ...cardItems?.data },
          user?.id,
          total,
          sessionInfo?.sessionId,
          orderType,
          guestUserData,
          tableData?.id,
          method,
          taxAmount,
          Number(subTotal),
          selectedTip === null ? 0 : selectedTip,
          totalServiceValue
        )
      );
      if (response?.status === 201) {
        await dispatch(getCartByCustomerId(sessionInfo?.sessionId));
        await dispatch(clearTableData());
        socket.emit("addIncomingOrder", { order: response.data });

        if (response.data.orderType == "QrDineIn") {
          socket.emit("addToTables", {
            table: { ...response.data.tableId, isSuperAdmin: false },
          });
        }

        resetCart();
        navigate("/active");
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.response.data.message);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [cardItems, tableData]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen width="100px" height="100px" marginT="0" h="70vh" />
      ) : (
        <Box
          mb={5}
          sx={{
            marginLeft: "35px",
            marginRight: "35px",
            "@media (max-width: 1025px)": {
              marginLeft: "0px",
              marginRight: "0px",
            },
          }}
        >
          <Container maxWidth="xl">
            {!user?.isPhoneVerified && isLoggedIn && (
              <Card
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.10)",
                  borderBottom: "none",
                  borderRadius: "20px",
                  marginTop: "140px",
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Grid>
                      <Box>
                        <Typography variant="h3">
                          Personal Information
                        </Typography>

                        <Typography variant="body1" color="error">
                          Your phone is not verified, please verify before
                          checkout.
                        </Typography>
                        <UpdateUserEmailOrPhone context="CartCheckoutSummary" />
                      </Box>
                    </Grid>
                  </Stack>
                </CardContent>
              </Card>
            )}

            <Grid
              container
              spacing={3}
              sx={{
                marginTop: "100px",
                alignItems: "stretch",
              }}
            >
              <Grid item xs={12} md={6}>
                <Box
                  mb={3}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.10)",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: "700",
                      marginLeft: "20px",
                      marginTop: "0px",
                      fontSize: "25px",
                      padding: "10px",
                    }}
                  >
                    Order Summary
                  </Typography>
                  <Divider />

                  {cardItems?.data?.items &&
                  cardItems?.data?.items?.length > 0 ? (
                    <>
                      {cardItems.data?.items?.map((cartItem, index) => (
                        <>
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              padding: "10px 0",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                gap: "30px",
                                "@media (max-width: 600px)": {
                                  gap: "10px",
                                  marginLeft: "0px",
                                },
                              }}
                            >
                              {cartItem.qty > 0 && (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    gap: "20px",
                                    color: "#F08203",
                                    marginLeft: "20px",
                                    "@media (max-width: 600px)": {
                                      gap: "10px",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {cartItem.qty}
                                  </Typography>
                                  <Typography sx={{ fontSize: "18px" }}>
                                    x
                                  </Typography>
                                </Stack>
                              )}
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    "@media (max-width: 600px)": {
                                      flexDirection: "column",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      "@media (max-width: 600px)": {
                                        marginLeft: "5px",
                                      },
                                    }}
                                  >
                                    {cartItem.name}
                                  </Typography>
                                  {cartItem?.selectedVariant && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                          color: "#666",
                                        }}
                                      >
                                        Variant:
                                      </Typography>
                                      <Typography sx={{ ml: 1, fontSize: 12 }}>
                                        {cartItem?.selectedVariant?.name}
                                      </Typography>
                                    </Box>
                                  )}

                                  {cartItem?.groups &&
                                    cartItem?.groups?.length > 0 && (
                                      <>
                                        {cartItem?.groups?.map((sauce) => (
                                          <Typography
                                            key={index}
                                            variant="caption"
                                            sx={{
                                              color: "#666",
                                              display: "flex",
                                              gap: "2px",
                                              fontWeight: "bold",
                                              "@media (max-width: 600px)": {
                                                fontSize: "10px",
                                              },
                                            }}
                                          >
                                            {sauce?.name} :
                                            <Typography
                                              variant="caption"
                                              sx={{
                                                color: "#666",
                                                display: "flex",
                                                gap: "2px",
                                                "@media (max-width: 600px)": {
                                                  fontSize: "10px",
                                                },
                                              }}
                                            >
                                              {sauce?.items?.map(
                                                (sauceItem, sauceIndex) => (
                                                  <span key={sauceIndex}>
                                                    {sauceItem?.item}
                                                    {` (Rs. ${
                                                      sauceItem.price *
                                                      cartItem.qty
                                                    })`}
                                                    {sauceIndex !==
                                                      sauce?.items?.length -
                                                        1 && ", "}
                                                  </span>
                                                )
                                              )}
                                            </Typography>
                                          </Typography>
                                        ))}
                                      </>
                                    )}
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "red",
                                      display: "flex",
                                      marginLeft: "10px",
                                      gap: "2px",
                                      "@media (max-width: 600px)": {
                                        fontSize: "10px",
                                      },
                                    }}
                                  >
                                    <span>{cartItem?.notes}</span>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Box>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#F08203",
                                  marginTop: "4px",
                                  fontSize: "16px",

                                  marginRight: "15px",
                                  "@media (max-width: 1440px)": {
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                {calculateMenuItemTotal(cartItem)}
                              </Typography>
                            </Stack>
                          </Box>

                          <Divider
                            sx={{
                              marginRight: "13px",
                              marginLeft: "8px",
                              marginTop: "10px",
                              color: "black",
                            }}
                          />
                        </>
                      ))}
                    </>
                  ) : (
                    <Box>
                      <Typography
                        sx={{
                          height: "20vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "25px",
                        }}
                      >
                        Cart is Empty
                      </Typography>
                    </Box>
                  )}
                  <>
                    {cardItems?.data?.items &&
                      cardItems?.data?.items?.length > 0 && (
                        <Link
                          href="#"
                          underline="none"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            marginRight: "20px",
                          }}
                        >
                          <Button
                            onClick={handleMenuPage}
                            size="small"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            sx={{
                              my: 1.5,
                              fontSize: "16px",
                              fontWeight: "400",
                            }}
                          >
                            Add More items
                          </Button>
                        </Link>
                      )}
                  </>
                </Box>

                {orderType === "QrPickUp" && (
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.10)",
                      borderBottom: "none",
                      borderRadius: "20px",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: "700",
                          marginLeft: "25px",
                          fontSize: "25px",
                          padding: "10px",
                        }}
                      >
                        Car Information
                      </Typography>
                      <Divider />
                    </Box>
                    {cardItems?.data?.customer && (
                      <CardContent>
                        <Stack spacing={2}>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary" }}
                            >
                              Car Number
                            </Typography>
                            {cardItems?.data?.items &&
                              cardItems?.data?.items?.length > 0 && (
                                <Typography variant="subtitle2">
                                  {cardItems?.data?.customer?.carNumber}
                                </Typography>
                              )}
                          </Stack>

                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary" }}
                            >
                              Car Color
                            </Typography>
                            {cardItems?.data?.items &&
                              cardItems?.data?.items?.length > 0 && (
                                <Typography variant="subtitle2">
                                  {cardItems?.data?.customer?.carColor}
                                </Typography>
                              )}
                          </Stack>

                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary" }}
                            >
                              Notes
                            </Typography>
                            {cardItems?.data?.items &&
                              cardItems?.data?.items?.length > 0 && (
                                <Typography variant="subtitle2">
                                  {cardItems?.data?.customer?.notes}
                                </Typography>
                              )}
                          </Stack>
                        </Stack>
                      </CardContent>
                    )}
                  </Card>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <CartCheckoutTotalSummary
                  method={method}
                  error={error}
                  setMethod={setMethod}
                  placeOrder={placeOrder}
                  cashAvailable={cashAvailable}
                  serviceFee={serviceFee}
                  taxAmount={taxAmount}
                  total={total}
                  isLoading={isLoadings}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};
export default CartCheckoutSummary;

import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";

const ChoiceGroupItems = ({
    choiceByGroup,
    selectedSauces,
    toggleSauce
}) => {

    return (
        <>
            {choiceByGroup?.groups?.length > 0 && (
                <Box sx={{mt:2}}>
                    {choiceByGroup?.groups?.map((elem, index) => {
                        return (
                            <Box key={index} sx={{mb:1}}>
                                <Typography
                                  sx={{ fontSize: 15 }} variant="subtitle2"
                                >
                                    {elem?.name}
                                    <Typography
                                    sx={{ fontSize: 12,ml:1 }} variant="span"
                                    >
                                        ({elem?.quantity}{" "}
                                        {elem?.required ? " Required" : " Optional"})
                                    </Typography>
                                </Typography>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1,
                                        marginTop: 1,
                                        fontWeight: "600"
                                    }}
                                >
                                    {elem?.items?.map((sauce, index) => (
                                        <Button
                                            size="small"
                                            sx={{
                                                minWidth: {
                                                    xs: 'calc(50% - 8px)',
                                                    sm: 'calc(50% - 8px)',
                                                    md: 120
                                                },
                                                minHeight: 40,
                                                flexGrow: { xs: 0, sm: 0, md: 0 },
                                                ml: { xs: 0, sm: 0, md: 0 },
                                                mb: 1,
                                                color: selectedSauces.items.some((item) =>
                                                    item.items.includes(sauce)
                                                )
                                                    ? "black"
                                                    : " #fda92d",
                                            }}
                                            variant={
                                                selectedSauces.items.some((item) =>
                                                    item.items.includes(sauce)
                                                )
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            key={index}
                                            onClick={() => toggleSauce(elem, sauce)}
                                        >
                                            {sauce?.item} (Rs. {sauce.price})
                                        </Button>
                                    ))}
                                </Box>
                                <Divider sx={{
                                    mt:1
                                }}/> 
                            </Box>
                        );
                    })}
                </Box>
            )}

        </>


    );
};

export default ChoiceGroupItems;

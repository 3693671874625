import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Tab, Tabs } from "@mui/material";
import Image from "src/components/image/Image";
import { getVenueListWithLocationAndType } from "src/redux/slices/venueSlice";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import SwitchOrderTypeModal from "src/pages/switchOrderTypeModal/SwitchOrderTypeModal";
import useSession from "src/utils/useSession";
import { selectedVenue } from "src/utils/venueSelectionUtils";
import { clearTableData } from "src/redux/slices/tableSlice";

export function MUIButtonsPage({ TABS }) {
  const [currentTab, setCurrentTab] = useState("");
  const [fixedTabs, setFixedTabs] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = TABS?.map((menu) => {
        const section = document.getElementById(menu.name);
        return {
          title: menu.name,
          offset: section ? section.getBoundingClientRect().top : 0,
        };
      });
      const inViewSection = sectionOffsets?.find(
        (offset) => offset.offset > 0 && offset.offset < 190
      );
      if (inViewSection) {
        setCurrentTab(inViewSection.title);
      }
      if (window.scrollY > 190) {
        setFixedTabs(true);
      } else {
        setFixedTabs(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [TABS]);

  const handleTitleClick = (name) => {
    const section = document.getElementById(name);
    if (section) {
      const yOffset = -200;
      const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      setCurrentTab(name);
    }
  };

  return (
    <>
      <Helmet>
        <title>Egora</title>
      </Helmet>
      <Box
        sx={{
          paddingTop: "20px",
          "@media (max-width: 600px)": {
            paddingTop: "32px",
          },
        }}
      >
        <Box
          sx={{
            position: fixedTabs ? "fixed" : "static",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            background: "#FEF5E4",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            padding: "8px",
            "@media (max-width: 1440px)": {
              top: 72,
            },
            "@media (max-width: 900px)": {
              top: 122,
            },
            "@media (max-width: 600px)": {
              top: 100,
            },
          }}
        >
          <Tabs
            value={currentTab}
            onChange={(events, newValue) => setCurrentTab(newValue)}
            variant="scrollable"
            scrollButtons="off"
          >
            {TABS.map((tab) => (
              <Tab
                value={tab.name}
                label={tab.name}
                key={tab.id}
                id={`${tab.name}-title`}
                className="menu-title"
                onClick={() => handleTitleClick(tab.name)}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </>
  );
}

export function HeaderTabs() {
  const [openModal, setOpenModal] = useState(false);
  const sessionInfo = useSession();
  const handleClose = () => {
    setOpenModal(false);
  };
  let orderType = localStorage.getItem("mode");
  if(orderType=="QrDineIn"){
    orderType="dineIn"
  }else if(orderType=="QrPickUp"){
    orderType="pickUp"
  }

  const [currentTab, setCurrentTab] = useState(orderType);
  let address = localStorage.getItem("address");
  let dispatch = useDispatch();
  const { tableData } = useSelector((state) => state.tableData);
  const { cardItems, isPickUp, isDineIn } = useSelector(
    (state) => state.cardSlice
  );

  // const selectedVenue = async (type, val) => {
  //   try {
  //     const results = await getGeocode({ address });
  //     const { lat, lng } = await getLatLng(results[0]);
  //     const location = `${lat},${lng}`;
  //     localStorage.setItem("address", results[0]?.formatted_address);
  //     localStorage.setItem("mode", type === "isDineIn" ? "dineIn" : "pickUp");
  //     await dispatch(getVenueListWithLocationAndType(location, type, val));
  //   } catch (error) {
  //     console.log("Error ", error);
  //   }
  // };
  const handleTabChange = async (newValue) => {
    // if no item into thr cart
    if (
      cardItems?.length === 0 ||
      cardItems?.data === "" ||
      cardItems?.data?.items.length === 0
    ) {
      if (newValue === "dineIn") {
        setCurrentTab(newValue);
        await selectedVenue(dispatch,"isDineIn", true);
      } else if (newValue === "pickUp") {
        setCurrentTab(newValue);
        // dispatch(clearTableData());
        await selectedVenue(dispatch,"isPickUp", true);
      }
      // some item into the cart but table not set.
    } else if (cardItems?.data?.items?.length > 0 && tableData.length === 0) {
      if (isDineIn === true && isPickUp === true) {
        if (newValue === "dineIn") {
          setCurrentTab(newValue);
          await selectedVenue(dispatch,"isDineIn", true);
        } else if (newValue === "pickUp") {
          setCurrentTab(newValue);
          await selectedVenue(dispatch,"isPickUp", true);
          // dispatch(clearTableData());
        }
      } else if (isDineIn === true && isPickUp === false) {
        if (newValue === "dineIn") {
          setCurrentTab(newValue);
          await selectedVenue(dispatch,"isDineIn", true);
        } else if (newValue === "pickUp") {
          setOpenModal(true);
        }
      } else if (isDineIn === false && isPickUp === true) {
        if (newValue === "dineIn") {
          setOpenModal(true);
        } else if (newValue === "pickUp") {
          setCurrentTab(newValue);
          await selectedVenue(dispatch,"isPickUp", true);
          // dispatch(clearTableData());
        }
      }
    } else if (cardItems?.data?.items?.length > 0 && tableData) {
      if (newValue === "dineIn") {
        setCurrentTab(newValue);
        await selectedVenue(dispatch,"isDineIn", true);
      } else if (newValue === "pickUp") {
        setOpenModal(true);
      }
    } else {
      console.log("elseee");
    }
  };

  const TABS = [
    {
      name: "dineIn",
      img: "/assets/header-tabs/dine-in-orange.png",
      imgblack: "/assets/header-tabs/dine-in.png",
    },
    {
      name: "pickUp",
      img: "/assets/header-tabs/pick-up-orange.png",
      imgblack: "/assets/header-tabs/pick-up.png",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Egora</title>
      </Helmet>

      <Box
        sx={{
          zIndex: 999,
          background: "transparent",
          paddingBottom: "8px",
        }}
      >
        <Tabs
          value={currentTab}
          TabIndicatorProps={{ style: { height: 4 } }}
          onChange={(event, newValue) => handleTabChange(newValue)}
          variant="scrollable"
          scrollButtons="off"
        >
          {TABS.map((tab) => (
            <Tab
              value={tab.name}
              sx={{
                // maxWidth: "200px",
                margin: "10px 0px",
                fontWeight: "bold",
                "&.Mui-selected": {
                  color: "#FFA500",
                },
                "@media (max-width: 600px)": {
                  margin: "0px",
                },
              }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src={currentTab === tab.name ? tab.img : tab.imgblack}
                    alt={tab.name}
                    sx={{
                      marginRight: "8px",
                      width: "40px",
                      "@media (max-width: 1025px)": {
                        width: "30px",
                      },
                    }}
                  />
                  {tab.name}
                </Box>
              }
              key={tab.name}
            />
          ))}
        </Tabs>
        {/* </Box> */}
      </Box>

      {openModal && (
        <SwitchOrderTypeModal
          width="sm"
          open={openModal}
          onClose={handleClose}
          selectedVenue={selectedVenue}
          setCurrentTab={setCurrentTab}
        />
      )}
    </>
  );
}

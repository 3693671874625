import { useState } from "react";
import {
  Box,
  Card,
  Stack,
  Divider,
  Typography,
  CardContent,
  IconButton,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { useCart } from "../CartContext";
import { useSelector } from "react-redux";
import LoginModal from "src/sections/auth/modal/loginModal/LoginModal";
import { PaymentMethods } from "src/sections/payment";
import { LoadingButton } from "@mui/lab";
const CartCheckoutTotalSummary = ({
  method,
  setMethod,
  placeOrder,
  cashAvailable,
  taxAmount,
  serviceFee,
  error,
  total,
  isLoading,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const { calculateSubTotal, selectedTip } = useCart();
  const { cardItems } = useSelector((state) => state.cardSlice);

  const { user, isLoggedIn } = useSelector((state) => state.login);


  return (
    <>
      <Card
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.10)",
          borderBottom: "none",
          borderRadius: "20px",
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              marginLeft: "25px",
              fontSize: "25px",
              padding: "10px",
            }}
          >
            Order Total
          </Typography>
          <Divider />
        </Box>

        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Sub Total
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items?.length > 0 && (
                <Typography variant="subtitle2">
                  Rs. {calculateSubTotal(cardItems?.data?.items)}
                </Typography>
              )}
            </Stack>

            {cardItems?.data?.venueDetail?.configurations?.isServiceFees && (
              <>
                {cardItems?.data?.venueDetail?.serviceFeesObject
                  ?.paymentOption === "Both" &&
                (method == "cash" || method == "card") ? (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Service Fee{" "}
                      {`${
                        cardItems?.data?.venueDetail?.serviceFeesObject
                          ?.type === "Percentage"
                          ? `(${cardItems?.data?.venueDetail?.serviceFeesObject?.amount}%)`
                          : ""
                      }`}
                      <IconButton>
                        <Iconify
                          sx={{
                            height: "14px",
                            width: "14px",
                          }}
                          icon={"ph:info"}
                        />{" "}
                      </IconButton>
                    </Typography>
                    <Typography variant="subtitle2">
                      Rs. {serviceFee}
                    </Typography>
                  </Stack>
                ) : method === "cash" &&
                  cardItems?.data?.venueDetail?.serviceFeesObject
                    ?.paymentOption === "Cash" ? (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Service Fee{" "}
                      {`${
                        cardItems?.data?.venueDetail?.serviceFeesObject
                          ?.type === "Percentage"
                          ? `(${cardItems?.data?.venueDetail?.serviceFeesObject?.amount}%)`
                          : ""
                      }`}
                      <IconButton>
                        <Iconify
                          sx={{
                            height: "14px",
                            width: "14px",
                          }}
                          icon={"ph:info"}
                        />{" "}
                      </IconButton>
                    </Typography>
                    <Typography variant="subtitle2">
                      Rs. {serviceFee}
                    </Typography>
                  </Stack>
                ) : method === "card" &&
                  cardItems?.data?.venueDetail?.serviceFeesObject
                    ?.paymentOption === "Card" ? (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Service Fee{" "}
                      {`${
                        cardItems?.data?.venueDetail?.serviceFeesObject
                          ?.type === "Percentage"
                          ? `(${cardItems?.data?.venueDetail?.serviceFeesObject?.amount}%)`
                          : ""
                      }`}
                      <IconButton>
                        <Iconify
                          sx={{
                            height: "14px",
                            width: "14px",
                          }}
                          icon={"ph:info"}
                        />{" "}
                      </IconButton>
                    </Typography>
                    <Typography variant="subtitle2">
                      Rs. {serviceFee}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </>
            )}

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Tip
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items.length > 0 && (
                <Typography variant="subtitle2">
                  Rs. {selectedTip ? selectedTip : 0}
                </Typography>
              )}
            </Stack>

            {cardItems?.data?.venueDetail?.configurations?.isPayingTax && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Tax
                </Typography>
                <Typography variant="subtitle2">
                  Rs. {taxAmount ? taxAmount : 0}
                </Typography>
              </Stack>
            )}

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: "#FCA92E" }}>
                Total Amount
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items.length > 0 && (
                <Typography variant="subtitle2" sx={{ color: "#FCA92E" }}>
                  Rs. {total.toFixed(2)}
                </Typography>
              )}
            </Stack>

            <Box
              sx={{
                borderRadius: "20px",
              }}
            >
              <PaymentMethods
                method={method}
                cashAvailable={cashAvailable}
                setMethod={setMethod}
                serviceFee={serviceFee}
                taxAmount={taxAmount}
                total={total}
              />
            </Box>
            {error && (
              <Typography
                variant="body2"
                style={{ color: "red", textAlign: "center" }}
              >
                {error}
              </Typography>
            )}

            {method === "cash" && cashAvailable && (
              <>
                <Divider />
                <LoadingButton
                  fullWidth
                  color="inherit"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  onClick={placeOrder}
                  disabled={!user?.isPhoneVerified && isLoggedIn}
                  sx={{
                    bgcolor: "#fda92d",
                    color: (theme) =>
                      theme.palette.mode === "black" ? "common." : "grey.800",
                    "&:hover": {
                      bgcolor: "#B66816",
                      color: (theme) =>
                        theme.palette.mode === "black"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                >
                  Place Order
                </LoadingButton>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <LoginModal
          width="sm"
          open={openModal}
          onClose={closeModal}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  );
};

export default CartCheckoutTotalSummary;

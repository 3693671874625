import React, { useEffect, useState } from "react";
import { useCart } from "../CartContext";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTipToCart } from "src/redux/slices/cardSlice";

const CartModalAction = ({
  onClose,
  setIsDisabled,
  isDisabled,
  serviceFee,
  setIsScanning,
  taxAmount,
  setOpenCarInformationModal,
}) => {
  let orderType = localStorage.getItem("mode");
  const { cardItems } = useSelector((state) => state?.cardSlice);
  const { isLoggedIn } = useSelector((state) => state.login);
  const { calculateFinalTotal, selectedTip } = useCart();
  const navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    setIsDisabled(orderType === "dineIn" && !cardItems?.data.tableId);
  }, []);

  const handleCheckoutClick = () => {
    dispatch(addTipToCart(selectedTip))
    onClose();
    if (orderType == "QrPickUp") {
      setOpenCarInformationModal(true);
    } else if (isLoggedIn) {
      navigate(`/checkout`);
    } else {
      navigate(`/auth/login?type=${orderType}`);
    }
  };

  return (
    <>
      <Box>
        {cardItems?.data?.items?.length > 0 && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Total
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Rs.{" "}
                {(
                  Number(calculateFinalTotal(cardItems?.data?.items)) +
                  Number(taxAmount) +
                  Number(serviceFee)
                ).toFixed(2)}
              </Typography>
            </Box>

            {isDisabled && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                      marginTop: "10px",
                    }}
                  >
                    Please Scan the Table First Before Placing the Order
                  </Typography>


                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => setIsScanning(true)}
                    sx={{
                      height: "40px",
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingX: "16px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                        },
                      }}
                    >
                      Open QR Scanner
                    </Typography>
                  </Button>
                </Box>
              </>
            )}

            <Box
              sx={{
                marginTop: "10px",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleCheckoutClick}
                disabled={isDisabled}
                sx={{
                  width: "100%",
                  height: "40px",
                  fontSize: "16px",
                }}
              >
                Checkout
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                ></Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CartModalAction;

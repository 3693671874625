import UpdatePassword from "./updatePassword";
import UpdateUserProfileInfo from "./updateProfileInfo";
import UpdateUserEmailOrPhone from "./updateEmailOrPhone";
import UserCarInformation from "./UserCarInformation";

export default function UserProfile() {
  return (
    <>
      <UpdateUserProfileInfo />
      <UpdateUserEmailOrPhone />
      <UpdatePassword />
      <UserCarInformation/>
    </>
  );
}

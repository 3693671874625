import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLoading: false,
    error: null,
    user: null,
    isLoggedIn: false,
};

const slice = createSlice({
    name: 'authLogin',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        loginSuccess(state, action) {
            state.isLoading = false;
            state.user = action.payload;
            state.error = null;
            // state.isLoggedIn = true;
            state.isLoggedIn = action.payload.isEmailVerified || false;
        },
        loginFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        logout(state) {
            state.user = null;
            state.isLoggedIn = false;
        },
    },
});

export default slice.reducer;

export const { loginSuccess, loginFailure, logout } = slice.actions;

export function getProfile(customerID) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customers/${customerID}`);
            dispatch(slice.actions.loginSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.loginFailure(error.response?.data?.message));
            throw error
        }
    };
}

export function loginUser(credentials) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/authc/login`, credentials);
            dispatch(slice.actions.loginSuccess(response.data.customer));
            return { status: response.status, token: response.data.tokens.access.token ,data:response?.data?.customer}
        } catch (error) {
            dispatch(slice.actions.loginFailure(error.response?.data?.message));
            throw error
        }
    };
}

export function updateUserEmailOrPhone(credentials, id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/customers/UpdateEmailOrPhone/${id}`, credentials);
            // dispatch(slice.actions.loginSuccess(response?.data));
            // return { status: response.status }
        } catch (error) {
            dispatch(slice.actions.loginFailure(error.response?.data?.message));
            throw error
        }
    };
}

export function updateUserPassword(credentials, id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/customers/changePassword/${id}`, credentials);
            dispatch(slice.actions.loginSuccess(response?.data));
            return { status: response.status }
        } catch (error) {
            dispatch(slice.actions.loginFailure(error.response?.data?.message));
            throw error
        }
    };
}

export function updateUserProfileInfo(data, id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/customers/${id}`, data);
            dispatch(slice.actions.loginSuccess(response?.data));
            return { status: response.status }
        } catch (error) {
            dispatch(slice.actions.loginFailure(error.response?.data?.message));
            throw error
        }
    };
}

export function verifyOTP(apiData) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/authc/verify-otp`, apiData);
            dispatch(slice.actions.loginSuccess(response.data.customer));
            return { status: response.status, token: response.data.tokens.access.token }
        } catch (error) {
            dispatch(slice.actions.loginFailure(error.response?.data?.message));
            throw error
        }
    };
}



export function logoutUser() {
    return async (dispatch) => {
        dispatch(slice.actions.logout());
    };
}

import { useEffect } from "react";
import { Box, Divider, IconButton } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import VenueModalHeaderEdit from "./VenueModalHeaderEdit";
import VenueModalContentEdit from "./VenueModalContentEdit";
import VenueModalActionEdit from "./VenueModalActionEdit";
import { useSnackbar } from "../../../../components/snackbar";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateItemsToCard } from "src/redux/slices/cardSlice";

const VenueItmeModalEdit = ({
  width,
  open,
  item,
  onClose,
  data,
}) => {
  const { tableData } = useSelector((state) => state.tableData);

  const { enqueueSnackbar } = useSnackbar();
  const [notes, setNotes] = useState(item?.notes ? item?.notes : "");
  const dispatch = useDispatch();
  const { choiceByGroup } = useSelector(
    (state) => state.choiceByGroup
  );
  const [isLoadings, setIsLoading] = useState(false);

  const [selectedSauces, setSelectedSauces] = useState({
    items: []
  });


  useEffect(() => {

    if (item?.selectedVariant) {
      const initialSelectedSauces = item?.groups?.map((variant) => variant);
      setSelectedSauces({ items: initialSelectedSauces });
    }
    if (choiceByGroup?.groups) {
      const initialSelectedSauces = item?.groups.map((variant) => variant);
      setSelectedSauces({ items: initialSelectedSauces });
    }

  }, [item?.selectedVariant, choiceByGroup]);


  const [quantity, setQuantity] = useState(item?.qty ? item?.qty : 1);

  const [selectedVariant, setSelectedVariant] = useState([]);


  useEffect(() => {
    if (item?.selectedVariant) {
      setSelectedVariant(item.selectedVariant);
    }
  }, [item]);


  const toggleVariantSelect = (variant) => {
    setSelectedVariant(variant);
    setSelectedSauces({ items: [] });
  };

  const calculateTotalPrice = () => {
    const basePrice = selectedVariant?.length > 0
      ? parseFloat(selectedVariant.price)
      : parseFloat(item?.price);
    const addOnPrices = (item?.addOns || []).reduce((total, addon) => {
      const addonPrice = parseFloat(addon.price.replace("Rs. ", ""));
      return total + addonPrice;
    }, 0);

    let saucePrices = 0;
    if (selectedSauces?.items?.length > 0) {
      saucePrices = selectedSauces.items.map((sauce) => sauce.items).flat().reduce((total, item) => total + parseFloat(item.price), 0);
    }

    return `Rs. ${(basePrice + addOnPrices + saucePrices) * quantity}`;
  };


  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const toggleSauce = (elem, sauce) => {
    let updatedItems = selectedSauces.items.map(item => ({
      ...item,
      items: [...item.items]
    }));
    const itemIndex = updatedItems.findIndex((item) => item.id === elem.id);
    if (itemIndex !== -1) {
      const isSauceSelected = updatedItems[itemIndex].items.some(item => item.id === sauce.id);
      if (isSauceSelected) {
        updatedItems[itemIndex].items = updatedItems[itemIndex].items.filter(
          (selected) => selected.id !== sauce.id
        );
      } else {

        if (updatedItems[itemIndex].items.length < elem?.quantity) {
          updatedItems[itemIndex].items.push(sauce);
        } else {
          updatedItems[itemIndex].items.shift();
          updatedItems[itemIndex].items.push(sauce);
        }
      }
      if (updatedItems[itemIndex].items.length === 0) {
        updatedItems.splice(itemIndex, 1);
      }
    } else {
      updatedItems.push({ ...elem, items: [sauce] });
    }
    const isEmpty = updatedItems.length === 0;
    setSelectedSauces(isEmpty ? { items: [] } : { items: updatedItems });
  };


  const updateItemToCart = useCallback(async () => {
    let price = 0;
    if (selectedSauces?.items.length > 0) {
      price = selectedSauces.items.reduce((sum, elem) => {
        const elemTotal = elem.items
          ? elem.items.reduce(
            (innerSum, item) => innerSum + Number(item.price),
            0
          )
          : 0;
        return sum + elemTotal;
      }, 0);

      price = Math.round(price).toFixed(0);
    }
    try {
      setIsLoading(true);

      const items = {
        ...item,
        ...(item.hasVariant ? { selectedVariant: selectedVariant } : {}),
        isPrepared: false,
        price: item.hasVariant
          ? selectedVariant.price
          : item.price,
        priceWithChoiceGroup:
          Number(item.hasVariant ? selectedVariant.price : item.price) +
          Number(price),
      };
      let sessionId = data?.sessionId
      const res = await dispatch(
        updateItemsToCard(
          {
            ...items,
            cartItemId: item?.cartItemId,
            qty: quantity,
            notes: notes,
            groups: selectedSauces?.items,
          },
          sessionId
        )
      );
      if (res?.status === 201) {
        setQuantity("");
        onClose();
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      setIsLoading(false);
    }
    finally{
      setIsLoading(false);
    }
  }, [selectedSauces, quantity, notes, choiceByGroup.groups, tableData]);


  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <VenueModalHeaderEdit item={item} selectedVariant={selectedVariant} />
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ typography: "body2" }}>
          <Box>
            <Divider />
          </Box>
          <VenueModalContentEdit
            item={item}
            selectedSauces={selectedSauces}
            toggleSauce={toggleSauce}
            notes={notes}
            setNotes={setNotes}
            setSelectedSauces={setSelectedSauces}
            selectedVariant={selectedVariant}
            toggleVariantSelect={toggleVariantSelect}
          />
          <Divider sx={{ marginTop: "20px" }} />
        </DialogContent>
        <DialogActions sx={{ display: "block" }}>
          <VenueModalActionEdit
            quantity={quantity}
            setQuantity={setQuantity}
            handleIncreaseQuantity={handleIncreaseQuantity}
            handleDecreaseQuantity={handleDecreaseQuantity}
            selectedSauces={selectedSauces}
            calculateTotalPrice={calculateTotalPrice}
            updateItemToCart={updateItemToCart}
            selectedVariant={selectedVariant}
            isLoading={isLoadings}
          />

        </DialogActions>
      </ConfirmDialog>

    </>
  );
};
export default VenueItmeModalEdit;

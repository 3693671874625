import { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Stack,
  IconButton,
  InputAdornment,
  Box,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/iconify";
import FormProvider, { RHFTextField } from "../../../components/hook-form";
import { useDispatch } from "react-redux";
import { updateUserPassword } from "src/redux/slices/loginSlice";
import { useSelector } from "react-redux";
import { useSnackbar } from "../../../components/snackbar";

export default function UpdatePassword() {
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
        .required("Current Password is required")
        .min(8, "Password must be at least 8 characters long")
        .matches(/\d/, "Password must contain at least one number")
        .matches(/[a-zA-Z]/, "Password must contain at least one letter"),
    newPassword: Yup.string()
        .required("New Password is required")
        .min(8, "New Password must be at least 8 characters long")
        .matches(/\d/, "New Password must contain at least one number")
        .matches(/[a-zA-Z]/, "New Password must contain at least one letter"),
  });

  const defaultValues = {
    password: "",
    newPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;


  const onSubmit = async (data) => {
    try {
      const response = await dispatch(updateUserPassword(data, user?.id));
      if (response.status === 200) {
        enqueueSnackbar("Password updated successfully");
        reset({
            password: "",
            newPassword: "",
          });
        // navigate("/auth/login");
      }
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(`${error?.response?.data?.message}`, {
        variant: "error",
      });
      reset();
      setError("afterSubmit", {
        ...error,
        message: error?.response?.data?.message,
      });
    }
  };

  return (
    <>
      <Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} mt={4}>
          <RHFTextField
            name="password"
            label="Current Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <RHFTextField
            name="newPassword"
            label="New Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <LoadingButton
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            // loading={isSubmitting || isSubmitSuccessful}
            sx={{
              width: "15%",
              marginTop: "15px",
              bgcolor: "#FCA92E",
              color: (theme) =>
                theme.palette.mode === "light" ? "#000000" : "grey.800",
              "&:hover": {
                bgcolor: "text.primary",
                color: (theme) =>
                  theme.palette.mode === "light" ? "common.white" : "grey.800"
              }
            }}
          >
            Update
          </LoadingButton>
        </Stack>
      </FormProvider>
      </Box>
    </>
  );
}
